import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { roleLandingMap } from '@shared/constants/role-landing.map';
import isNil from 'lodash/isNil';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import * as fromRoot from '../store/reducers';

@Injectable({ providedIn: 'root' })
export class LandingGuard implements CanActivate {
  constructor(private readonly store$: Store<fromRoot.State>, private readonly router: Router) {}

  public canActivate(): Observable<UrlTree | false> {
    return this.store$.pipe(
      select(fromRoot.selectUserRole),
      first((role) => !isNil(role)),
      map((role) => {
        const landing = roleLandingMap.get(role);
        if (isNil(landing)) {
          return false;
        }
        return this.router.createUrlTree(landing);
      })
    );
  }
}
