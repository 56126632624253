import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptor } from '@core/auth/auth.interceptor';
import { AuthService } from '@core/auth/auth.service';
import { RetryInterceptor } from '@core/interceprtos/retry.interceptor';
import { TimeoutInterceptor } from '@core/interceprtos/timeout.interceptor';
import { SvgIconsModule } from '@ngneat/svg-icon';
import { ExpansionPanelModule } from '@shared/modules/expansion-panel/expansion-panel.module';
import { ToastMessageModule } from '@shared/modules/toast-message/toast-message.module';
import { ApiModule, Configuration } from '@wtax/data-angular';
import * as icons from '../assets/icons';
import { environment } from '../environments/environment';
import { Config, CONFIG_TOKEN } from '../wtax-config';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { NavigationItemComponent } from './components/header/navigation-item.component';
import { CoreModule } from './core/core.module';
import { MockInterceptor } from './mock/mock.interceptor';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [AppComponent, HeaderComponent, NavigationItemComponent, AuthCallbackComponent, FooterComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    SvgIconsModule.forRoot({
      icons: Object.values(icons),
    }),
    ToastMessageModule.forRoot(),
    BrowserAnimationsModule,
    CoreModule,
    ExpansionPanelModule,
    ApiModule,
  ],
  providers: [
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: {
        color: 'primary',
      },
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: {
        color: 'primary',
      },
    },
    // configure API package
    {
      provide: Configuration,
      useFactory: (config: Config, authService: AuthService) =>
        new Configuration({
          basePath: config.apiBaseUrl,
          accessToken: authService.getAccessToken(),
        }),
      deps: [CONFIG_TOKEN, AuthService],
    },
    // intercepting 401 responses
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    // enable mocking based on environment configuration
    environment.debug
      ? [
          {
            provide: HTTP_INTERCEPTORS,
            useClass: MockInterceptor,
            multi: true,
          },
        ]
      : [],
    // intercepting failed responses and retrying them
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeoutInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [NavigationItemComponent, FooterComponent],
})
export class AppModule {}
