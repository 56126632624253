import { Profile } from '@shared/interfaces/profile.interface';
import { UserProfile } from '@wtax/data-angular';
import { mapName } from './map-name.helper';

export const mapUserProfile = (profile: UserProfile, fallbackCurrency: string): Profile => ({
  id: profile.id,
  email: profile.email,
  name: {
    title: profile.name?.title,
    firstName: profile.name?.first_name,
    lastName: profile?.name?.last_name,
  },
  accountCompleteness: profile.account_completeness,
  role: profile.role,
  defaultCurrency: profile.default_currency_code ?? fallbackCurrency,
  signatoryDetailsStatus: profile.signatory_details_status,
  discretionAuthorization: profile.discretion_authorization,
  accountManager: {
    id: profile.account_manager?.id,
    name: mapName(profile.account_manager?.name),
    profileImage: profile.account_manager?.profile_image_url,
    role: profile.account_manager?.role,
  },
  officeAddress: profile.office_address,
  totalEstimatedValue: profile.total_estimated_value,
  restrictedToAccountSetup: profile.restricted_to_account_setup || false,
  messaging: profile.messaging,
});
