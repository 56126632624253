<div class="container py-0-5">
  <a [routerLink]="rootLink$ | async">
    <img class="logo" src="assets/images/logo.svg" alt="WTax" />
  </a>

  <nav class="navigation" [class.disabled]="accountCreationInProgress$ | async">
    <app-navigation-item
      *ngFor="let item of items$ | async"
      class="navigation-item"
      [menuItem]="item"
      [disabled]="accountCreationInProgress$ | async"
    ></app-navigation-item>
  </nav>

  <div #profile class="profile" [class.active]="profileVisible$ | async">
    <svg-icon key="profile"></svg-icon>

    <ul class="profile-menu" [class.visible]="profileVisible$ | async">
      <li *ngIf="hasAccountSettings$ | async" [class.disabled]="accountCreationInProgress$ | async">
        <a [routerLink]="(accountCreationInProgress$ | async) === false ? myAccountLink : undefined">{{
          'HEADER.MY_ACCOUNT' | translate
        }}</a>
      </li>
      <li>
        <span (click)="onLogoutClick()">{{ 'HEADER.LOGOUT' | translate }}</span>
      </li>
    </ul>
  </div>
</div>
