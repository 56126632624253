import { Injectable, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable({ providedIn: 'root' })
export class GtmWrapperService {
  constructor(private readonly router: Router, private readonly gtmService: GoogleTagManagerService, private readonly zone: NgZone) {}

  public startTracking(): void {
    this.zone.runOutsideAngular(() => {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          const gtmTag = {
            event: 'page',
            pageName: event.urlAfterRedirects || event.url,
          };
          this.gtmService.pushTag(gtmTag);
        }
      });
    });
  }
}
