import { HttpRequest, HttpResponse } from '@angular/common/http';
import { CreateUserRequest, User, UserRole, UserStatus } from '@wtax/data-angular';
import { randomBool, randomId } from '../functions';

export class UsersMockService {
  private readonly store: Map<string, User>;

  constructor() {
    this.store = new Map<string, User>();
  }

  public getUsers(_: HttpRequest<any>): HttpResponse<User[]> {
    const users = Array.from(this.store.values());

    return new HttpResponse({ status: 200, body: users });
  }

  public createUsers(request: HttpRequest<CreateUserRequest[]>): HttpResponse<User[]> {
    const users: User[] = request.body.map((createUserRequest) => ({
      id: randomId(),
      status: randomBool() ? UserStatus.PENDING : UserStatus.REGISTERED,
      role: UserRole.WEALTHMANAGER,
      name: createUserRequest.name,
      email: createUserRequest.email,
      work_phone: createUserRequest.work_phone,
      mobile_phone: createUserRequest.mobile_phone,
    }));

    users.forEach((user) => {
      this.store.set(user.id, user);
    });

    return new HttpResponse({ status: 200, body: users });
  }
}
