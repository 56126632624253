import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Config, CONFIG_TOKEN } from 'wtax-config';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(@Inject(CONFIG_TOKEN) private readonly config: Config, private readonly authService: AuthService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // only intercept API requests
    if (!request.url.startsWith(this.config.apiBaseUrl)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error && error.status === 401) {
          // eslint-disable-next-line no-console
          console.log('[AUTH-INTERCEPTOR] 401 response detected, logging out');
          this.authService.logout();
        }

        return throwError(`unhandled error`);
      })
    );
  }
}
