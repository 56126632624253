<span *ngIf="disabled; else enabledState" class="font-size-reduced navigation-item-container disabled">
  {{ menuItem.name | translate }}
</span>

<ng-template #enabledState>
  <a
    *ngIf="menuItem.type === MenuItemType.RouterLink"
    class="font-size-reduced navigation-item-container"
    [routerLink]="menuItem.link"
    routerLinkActive="active"
  >
    {{ menuItem.name | translate }}

    <ng-container [ngTemplateOutlet]="badgeTemplate"></ng-container>
  </a>

  <a
    *ngIf="menuItem.type === MenuItemType.Href"
    class="font-size-reduced navigation-item-container"
    [href]="menuItem.link"
    [target]="menuItem.openInNewTab ? '_blank' : '_self'"
  >
    {{ menuItem.name | translate }}

    <ng-container [ngTemplateOutlet]="badgeTemplate"></ng-container>
  </a>
</ng-template>

<ng-template #badgeTemplate>
  <span *ngIf="menuItem.badge$" class="badge" [ngClass]="menuItem.badge$ | async"></span>
</ng-template>
