import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import * as fromProfile from '@core/store/reducers';
import * as fromRoot from '@core/store/reducers';
import { select, Store } from '@ngrx/store';
import { RouteSegment } from '@shared/enums/route-segment.enum';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MessagesAllowedGuard implements CanActivate {
  constructor(private readonly store$: Store<fromRoot.State>, private readonly router: Router) {}

  public canActivate(): Observable<boolean | UrlTree> {
    return this.store$.pipe(
      select(fromProfile.selectUserProfile),
      map((user) => (user.messaging ? true : this.router.createUrlTree([RouteSegment.Root])))
    );
  }
}
