import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/auth/auth.guard';
import { AccountCompletenessGuard } from '@core/guards/account-completeness.guard';
import { LandingGuard } from '@core/guards/landing.guard';
import { MessagesAllowedGuard } from '@core/guards/messages-allowed.guard';
import { MvpRestrictedGuard } from '@core/guards/mvp-restricted.guard';
import { RoleGuard } from '@core/guards/role.guard';
import { AccountCompleteness, UserRole } from '@wtax/data-angular';
import { AuthCallbackComponent } from '../app/components/auth-callback/auth-callback.component';
import { RouteData } from './shared/enums/route-data.enum';
import { RouteSegment } from './shared/enums/route-segment.enum';

const routes: Routes = [
  {
    path: RouteSegment.AuthCallback,
    component: AuthCallbackComponent,
  },
  {
    path: RouteSegment.Root,
    canActivate: [AuthGuard],
    children: [
      {
        path: RouteSegment.Root,
        pathMatch: 'full',
        children: [],
        canActivate: [LandingGuard],
      },
      {
        path: RouteSegment.Dashboard,
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canLoad: [MvpRestrictedGuard],
        canActivate: [AccountCompletenessGuard, RoleGuard, MvpRestrictedGuard],
        data: {
          [RouteData.RequiredCompleteness]: AccountCompleteness.COMPLETED,
          [RouteData.AllowedRoles]: [UserRole.WEALTHMANAGER, UserRole.FUNDMANAGER, UserRole.BENEFICIALOWNER],
        },
      },
      {
        path: RouteSegment.Reporting,
        loadChildren: () => import('./modules/reporting-page/reporting-page.module').then((m) => m.ReportingPageModule),
        canActivate: [AccountCompletenessGuard, RoleGuard],
        data: {
          [RouteData.RequiredCompleteness]: AccountCompleteness.COMPLETED,
          [RouteData.AllowedRoles]: [UserRole.WEALTHMANAGER, UserRole.FUNDMANAGER],
        },
      },
      {
        path: RouteSegment.Insights,
        loadChildren: () => import('./modules/insights-page/insights-page.module').then((m) => m.InsightsPageModule),
        canActivate: [AccountCompletenessGuard, RoleGuard],
        data: {
          [RouteData.RequiredCompleteness]: AccountCompleteness.COMPLETED,
          [RouteData.AllowedRoles]: [UserRole.WEALTHMANAGER, UserRole.FUNDMANAGER],
        },
      },
      {
        path: RouteSegment.ClientManagement,
        loadChildren: () => import('@modules/client-management/client-management.module').then((m) => m.ClientManagementModule),
        canActivate: [AccountCompletenessGuard, RoleGuard],
        data: { [RouteData.RequiredCompleteness]: AccountCompleteness.COMPLETED, [RouteData.AllowedRoles]: [UserRole.WEALTHMANAGER] },
      },
      {
        path: RouteSegment.FundManagement,
        loadChildren: () => import('@modules/fund-management/fund-management.module').then((m) => m.FundManagementModule),
        canActivate: [AccountCompletenessGuard, RoleGuard],
        data: { [RouteData.RequiredCompleteness]: AccountCompleteness.COMPLETED, [RouteData.AllowedRoles]: [UserRole.FUNDMANAGER] },
      },
      {
        path: RouteSegment.AccountSetup,
        loadChildren: () => import('./modules/account-setup/account-setup.module').then((m) => m.AccountSetupModule),
        canActivate: [AccountCompletenessGuard],
        data: { [RouteData.RequiredCompleteness]: AccountCompleteness.INCOMPLETE },
      },
      {
        path: RouteSegment.Payments,
        loadChildren: () => import('./modules/payments/payments.module').then((m) => m.PaymentsModule),
        canActivate: [AccountCompletenessGuard, RoleGuard],
        data: {
          [RouteData.RequiredCompleteness]: AccountCompleteness.COMPLETED,
          [RouteData.AllowedRoles]: [UserRole.WEALTHMANAGER, UserRole.FUNDMANAGER, UserRole.BENEFICIALOWNER],
        },
      },
      {
        path: RouteSegment.MyAccount,
        loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfileModule),
        canActivate: [AccountCompletenessGuard],
        data: { [RouteData.RequiredCompleteness]: AccountCompleteness.COMPLETED },
      },
      {
        path: RouteSegment.YourTasks,
        loadChildren: () => import('./modules/your-tasks/your-tasks.module').then((m) => m.YourTasksModule),
        canActivate: [AccountCompletenessGuard, RoleGuard],
        data: {
          [RouteData.RequiredCompleteness]: AccountCompleteness.COMPLETED,
          [RouteData.AllowedRoles]: [UserRole.BENEFICIALOWNER, UserRole.INVESTOR],
        },
      },
      {
        path: RouteSegment.Messages,
        loadChildren: () => import('./modules/message-list/message-list.module').then((m) => m.MessageListModule),
        canActivate: [AccountCompletenessGuard, MessagesAllowedGuard],
        data: { [RouteData.RequiredCompleteness]: AccountCompleteness.COMPLETED },
      },
    ],
  },
  { path: '**', redirectTo: RouteSegment.Root },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
