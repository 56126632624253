import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuItemType } from './menu-item-type.enum';
import { MenuItem } from './menu-item.interface';

@Component({
  selector: 'app-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationItemComponent {
  @Input() public menuItem: MenuItem;
  @Input() public disabled: boolean;

  public readonly MenuItemType = MenuItemType;
}
