export enum RouteSegment {
  Root = '',
  Dashboard = 'dashboard',
  Payments = 'payments',
  ClientManagement = 'client-management',
  FundManagement = 'fund-management',
  AccountSetup = 'account-setup',
  MyAccount = 'my-account',
  AuthCallback = 'auth/callback',
  YourTasks = 'your-tasks',
  Messages = 'messages',
  Reporting = 'reporting',
  Insights = 'insights',
}
