import { HttpRequest, HttpResponse } from '@angular/common/http';
import {
  DocumentDigitalStatus,
  DocumentOriginalStatus,
  DocumentStatus,
  DocumentType,
  DocumentTypeClient,
  DocumentTypeClientBatch,
  DocumentTypeClientsMetadata,
  DocumentTypeClientStatusCount,
  DocumentTypeDigital,
  DocumentTypeOriginal,
  DocumentTypesMetadata,
  DocumentTypesResponse,
  DocumentTypeUpload,
  DocumentUploadStatus,
  YesNo,
} from '@wtax/data-angular';
import { paginate, randomElement, randomEnumValue, randomId } from '../functions';
import { AttachmentsMockService } from './attachments-mock.service';

type DocumentRelatedData =
  | { documentType: DocumentTypeDigital; status: DocumentDigitalStatus }
  | { documentType: DocumentTypeOriginal; status: DocumentOriginalStatus }
  | { documentType: DocumentTypeUpload; status: DocumentUploadStatus };

const CLIENTS_COUNT = 20;

const DIGITAL_DOCUMENT_NAMES = [
  'General - letter of authority',
  'Germany - letter of authority',
  'Ukraine - letter of authority',
  'Spain - letter of authority',
  'Hungary - letter of authority',
  'Nepal - letter of authority',
  'Canada - RC59 Form',
  'USA - W-8BEN',
  'USA - 2848 Form',
];

const ORIGINAL_DOCUMENT_NAMES = [
  'General - letter of authority',
  'Germany - letter of authority',
  'Ukraine - letter of authority',
  'Spain - letter of authority',
  'Hungary - letter of authority',
  'Nepal - letter of authority',
  'Canada - RC59 Form',
  'USA - W-8BEN',
  'USA - 2848 Form',
];

const UPLOAD_DOCUMENT_NAMES = ['Constitutional Documents', 'Type 1', 'Type 2', 'Type 3', 'Type 4'];
const UPLOAD_DIVIDEND_DOCUMENT_NAME = 'Bank Statements';
const UPLOAD_CLAIM_DOCUMENT_NAME = 'Claim form';
const UPLOAD_FUND_INFORMATION_DOCUMENT_NAME = 'Fund Information';

const CLIENT_NAMES = [
  'Eco Focus',
  'Innovation Arch',
  'Strat Security',
  'Inspire Fitness Co',
  'Candor Corp',
  'Cogent Data',
  'Epic Adventure Inc',
  'Sanguine Skincare',
  'Vortex Solar',
  'Admire Arts',
  'Bravura Inc',
  'Bonefete Fun',
  'Moxie Marketing',
  'Zeal Wheels',
  'Obelus Concepts',
  'Quad Goals',
  'Erudite Learning',
  'Cipher Publishing',
  'Flux Water Gear',
  'Lambent Illumination',
  'Ryan, Stamm and Ebert',
  'Fadel - Muller',
  'Reilly LLC',
  'Medhurst Group',
  'Aufderhar LLC',
  'Cassin LLC',
  'Kohler, Lubowitz and Langosh',
  'Nienow Group',
  'Gulgowski - Okuneva',
  'Roob LLC',
  'Simonis and Sons',
  'Bartoletti - Effertz',
  'Rath, Bayer and Rempel',
  'Grady, Renner and West',
  'Botsford, Dickinson and Schoen',
  'Lynch, Hills and Romaguera',
  'Hand, Gislason and Ritchie',
  'Marquardt - McClure',
  'Murray LLC',
  'Lowe, Kuhic and Buckridge',
];

const DISCRETION_DIGITAL_DOCUMENT_TYPE_STATUSES = [
  DocumentDigitalStatus.MISSINGINFORMATION,
  DocumentDigitalStatus.READYFORSIGNING,
  DocumentDigitalStatus.WAITINGFORSIGNATURE,
  DocumentDigitalStatus.SIGNATURERECEIVED,
];

const NON_DISCRETION_DIGITAL_DOCUMENT_TYPE_STATUSES = [
  DocumentDigitalStatus.MISSINGINFORMATION,
  DocumentDigitalStatus.MISSINGSIGNATURE,
  DocumentDigitalStatus.SIGNATURERECEIVED,
];

const DISCRETION_ORIGINAL_DOCUMENT_TYPE_STATUSES = [
  DocumentOriginalStatus.MISSINGINFORMATION,
  DocumentOriginalStatus.READYFORPRINTING,
  DocumentOriginalStatus.WAITINGTOBERECEIVED,
  DocumentOriginalStatus.RECEIVED,
];

const NON_DISCRETION_ORIGINAL_DOCUMENT_TYPE_STATUSES = [
  DocumentOriginalStatus.MISSINGINFORMATION,
  DocumentOriginalStatus.MISSINGDOCUMENT,
  DocumentOriginalStatus.RECEIVED,
];

export class DocumentTypesMockService {
  private readonly uploadDocumentTypes = this.createUploadDocumentTypes();

  private readonly discretionalDocumentTypes: DocumentTypesResponse = {
    digital: this.createDiscretionDigitalDocumentTypes(),
    original: this.createDiscretionOriginalDocumentTypes(),
    upload: this.uploadDocumentTypes,
  };
  private readonly nonDiscretionalDocumentTypes: DocumentTypesResponse = {
    digital: this.createNonDiscretionDigitalDocumentTypes(),
    original: this.createNonDiscretionOriginalDocumentTypes(),
    upload: this.uploadDocumentTypes,
  };

  private readonly discretionClientsMap: Map<string, DocumentTypeClient[]> = this.createDiscretionDocumentTypeClientsMap();
  private readonly nonDiscretionClientsMap: Map<string, DocumentTypeClient[]> = this.createNonDiscretionDocumentTypeClientsMap();

  constructor(private readonly attachmentsMockService: AttachmentsMockService) {}

  private createDiscretionDocumentTypeClientsMap(): Map<string, DocumentTypeClient[]> {
    const clientsMap = new Map<string, DocumentTypeClient[]>();
    this.discretionalDocumentTypes.digital.forEach((documentType) => {
      const clients: DocumentTypeClient[] = [];
      for (let i = 0; i <= CLIENTS_COUNT; i++) {
        const status = randomElement(DISCRETION_DIGITAL_DOCUMENT_TYPE_STATUSES);
        clients.push(this.createDocumentTypeClient({ documentType, status }));
        documentType.total_count++;
        if (status === DocumentDigitalStatus.SIGNATURERECEIVED) {
          documentType.signed_count++;
        }
      }
      clientsMap.set(documentType.id, clients);
    });
    this.discretionalDocumentTypes.original.forEach((documentType) => {
      const clients: DocumentTypeClient[] = [];
      for (let i = 0; i <= CLIENTS_COUNT; i++) {
        const status = randomElement(DISCRETION_ORIGINAL_DOCUMENT_TYPE_STATUSES);
        clients.push(this.createDocumentTypeClient({ documentType, status }));
        documentType.total_count++;
        if (status === DocumentOriginalStatus.RECEIVED) {
          documentType.printed_count++;
        }
      }
      clientsMap.set(documentType.id, clients);
    });
    this.discretionalDocumentTypes.upload.forEach((documentType) => {
      const clients: DocumentTypeClient[] = [];
      for (let i = 0; i <= CLIENTS_COUNT; i++) {
        const status = randomEnumValue(DocumentUploadStatus);
        clients.push(this.createDocumentTypeClient({ documentType, status }));
        documentType.total_count++;
        if (status === DocumentUploadStatus.UPLOADED) {
          documentType.uploaded_count++;
        }
      }
      clientsMap.set(documentType.id, clients);
    });
    return clientsMap;
  }

  private createNonDiscretionDocumentTypeClientsMap(): Map<string, DocumentTypeClient[]> {
    const clientsMap = new Map<string, DocumentTypeClient[]>();
    this.nonDiscretionalDocumentTypes.digital.forEach((documentType) => {
      const clients: DocumentTypeClient[] = [];
      for (let i = 0; i <= CLIENTS_COUNT; i++) {
        const status = randomElement(NON_DISCRETION_DIGITAL_DOCUMENT_TYPE_STATUSES);
        clients.push(this.createDocumentTypeClient({ documentType, status }));
        documentType.total_count++;
        if (status === DocumentDigitalStatus.SIGNATURERECEIVED) {
          documentType.signed_count++;
        }
      }
      clientsMap.set(documentType.id, clients);
    });
    this.nonDiscretionalDocumentTypes.original.forEach((documentType) => {
      const clients: DocumentTypeClient[] = [];
      for (let i = 0; i <= CLIENTS_COUNT; i++) {
        const status = randomElement(NON_DISCRETION_ORIGINAL_DOCUMENT_TYPE_STATUSES);
        clients.push(this.createDocumentTypeClient({ documentType, status }));
        documentType.total_count++;
        if (status === DocumentOriginalStatus.RECEIVED) {
          documentType.printed_count++;
        }
      }
      clientsMap.set(documentType.id, clients);
    });
    this.nonDiscretionalDocumentTypes.upload.forEach((documentType) => {
      const clients: DocumentTypeClient[] = [];
      for (let i = 0; i <= CLIENTS_COUNT; i++) {
        const status = randomEnumValue(DocumentUploadStatus);
        clients.push(this.createDocumentTypeClient({ documentType, status }));
        documentType.total_count++;
        if (status === DocumentUploadStatus.UPLOADED) {
          documentType.uploaded_count++;
        }
      }
      clientsMap.set(documentType.id, clients);
    });
    return clientsMap;
  }

  private createDocumentTypeClient(documentRelatedData: DocumentRelatedData): DocumentTypeClient {
    return {
      id: randomId(),
      name: randomElement(CLIENT_NAMES),
      status: documentRelatedData.status as DocumentStatus,
      document_id: randomId(),
      attachments:
        documentRelatedData.status === DocumentOriginalStatus.RECEIVED || documentRelatedData.status === DocumentUploadStatus.UPLOADED
          ? this.attachmentsMockService.createAndStoreRandomAttachments()
          : [],
    };
  }

  private createDiscretionDigitalDocumentTypes(): DocumentTypeDigital[] {
    const documentTypes = [];
    const count = Math.floor(Math.random() * DIGITAL_DOCUMENT_NAMES.length);
    for (let i = 0; i <= count; i++) {
      documentTypes.push(this.createDiscretionDigitalDocumentType(DIGITAL_DOCUMENT_NAMES[i]));
    }
    return documentTypes;
  }

  private createNonDiscretionDigitalDocumentTypes(): DocumentTypeDigital[] {
    const documentTypes = [];
    const count = Math.floor(Math.random() * DIGITAL_DOCUMENT_NAMES.length);
    for (let i = 0; i <= count; i++) {
      documentTypes.push(this.createNonDiscretionDigitalDocumentType(DIGITAL_DOCUMENT_NAMES[i]));
    }
    return documentTypes;
  }

  private createDiscretionOriginalDocumentTypes(): DocumentTypeOriginal[] {
    const documentTypes = [];
    const count = Math.floor(Math.random() * ORIGINAL_DOCUMENT_NAMES.length);
    for (let i = 0; i <= count; i++) {
      documentTypes.push(this.createDiscretionOriginalDocumentType(ORIGINAL_DOCUMENT_NAMES[i]));
    }
    return documentTypes;
  }

  private createNonDiscretionOriginalDocumentTypes(): DocumentTypeOriginal[] {
    const documentTypes = [];
    const count = Math.floor(Math.random() * ORIGINAL_DOCUMENT_NAMES.length);
    for (let i = 0; i <= count; i++) {
      documentTypes.push(this.createNonDiscretionOriginalDocumentType(ORIGINAL_DOCUMENT_NAMES[i]));
    }
    return documentTypes;
  }

  private createUploadDocumentTypes(): DocumentTypeUpload[] {
    const documentTypes = [
      this.createDividendUploadDocumentType(UPLOAD_DIVIDEND_DOCUMENT_NAME),
      this.createClaimUploadDocumentType(UPLOAD_CLAIM_DOCUMENT_NAME),
      this.createFundInformationDocumentType(UPLOAD_FUND_INFORMATION_DOCUMENT_NAME),
    ];
    const count = Math.floor(Math.random() * UPLOAD_DOCUMENT_NAMES.length);
    for (let i = 0; i <= count - 1; i++) {
      documentTypes.push(this.createUploadDocumentType(UPLOAD_DOCUMENT_NAMES[i]));
    }
    return documentTypes;
  }

  private createDiscretionDigitalDocumentType(name: string): DocumentTypeDigital {
    const status = randomElement(DISCRETION_DIGITAL_DOCUMENT_TYPE_STATUSES);
    return {
      id: randomId(),
      name,
      signed_count: 0,
      total_count: 0,
      status,
      attachments: status === DocumentDigitalStatus.SIGNATURERECEIVED ? this.attachmentsMockService.createAndStoreRandomAttachments() : [],
      description: 'Description',
      signable: Math.random() < 0.5,
    };
  }

  private createNonDiscretionDigitalDocumentType(name: string): DocumentTypeDigital {
    return {
      id: randomId(),
      name,
      signed_count: 0,
      total_count: 0,
      status: randomElement(NON_DISCRETION_DIGITAL_DOCUMENT_TYPE_STATUSES),
      attachments: [],
      description: 'Description',
      signable: Math.random() < 0.5,
    };
  }

  private createDiscretionOriginalDocumentType(name: string): DocumentTypeOriginal {
    const status = randomElement(DISCRETION_ORIGINAL_DOCUMENT_TYPE_STATUSES);
    return {
      id: randomId(),
      name,
      printed_count: 0,
      total_count: 0,
      status,
      attachments: status === DocumentOriginalStatus.RECEIVED ? this.attachmentsMockService.createAndStoreRandomAttachments() : [],
      description: 'Description',
      url: `https://downloaddocument.com/${name}`,
    };
  }

  private createNonDiscretionOriginalDocumentType(name: string): DocumentTypeOriginal {
    return {
      id: randomId(),
      name,
      printed_count: 0,
      total_count: 0,
      status: randomElement(NON_DISCRETION_ORIGINAL_DOCUMENT_TYPE_STATUSES),
      attachments: [],
      description: 'Description',
      url: `https://downloaddocument.com/${name}`,
    };
  }

  private createUploadDocumentType(name: string): DocumentTypeUpload {
    const status = randomEnumValue(DocumentUploadStatus);
    const attachments = status === DocumentUploadStatus.UPLOADED ? this.attachmentsMockService.createAndStoreRandomAttachments() : [];
    return {
      id: randomId(),
      name,
      uploaded_count: 0,
      total_count: 0,
      status,
      attachments,
      description: 'Description',
      example_documents: this.attachmentsMockService.createAndStoreRandomAttachments(),
      type: DocumentType.CLIENT,
    };
  }

  private createDividendUploadDocumentType(name: string): DocumentTypeUpload {
    const status = randomEnumValue(DocumentUploadStatus);
    const attachments = status === DocumentUploadStatus.UPLOADED ? this.attachmentsMockService.createAndStoreRandomAttachments() : [];
    return {
      id: randomId(),
      name,
      uploaded_count: 0,
      total_count: 0,
      status,
      attachments,
      description: 'Description',
      example_documents: this.attachmentsMockService.createAndStoreRandomAttachments(1),
      type: DocumentType.DIVIDEND,
    };
  }

  private createClaimUploadDocumentType(name: string): DocumentTypeUpload {
    const status = randomEnumValue(DocumentUploadStatus);
    const attachments = status === DocumentUploadStatus.UPLOADED ? this.attachmentsMockService.createAndStoreRandomAttachments() : [];
    return {
      id: randomId(),
      name,
      uploaded_count: 0,
      total_count: 0,
      status,
      attachments,
      description: 'Description',
      example_documents: this.attachmentsMockService.createAndStoreRandomAttachments(1),
      type: DocumentType.CLAIM,
    };
  }

  private createFundInformationDocumentType(name: string): DocumentTypeUpload {
    const status = randomEnumValue(DocumentUploadStatus);
    const attachments = status === DocumentUploadStatus.UPLOADED ? this.attachmentsMockService.createAndStoreRandomAttachments() : [];
    return {
      id: randomId(),
      name,
      uploaded_count: 0,
      total_count: 0,
      status,
      attachments,
      description: 'Description',
      example_documents: this.attachmentsMockService.createAndStoreRandomAttachments(1),
      type: DocumentType.CLIENT,
      downloadable: true,
    };
  }

  public getDocumentTypes(request: HttpRequest<any>): HttpResponse<DocumentTypesResponse> {
    const discretion: YesNo = request.params.get('discretion') as YesNo;
    return new HttpResponse({
      status: 200,
      body: discretion === YesNo.True ? this.discretionalDocumentTypes : this.nonDiscretionalDocumentTypes,
    });
  }

  public getDocumentTypesMetadata(_: HttpRequest<any>): HttpResponse<DocumentTypesMetadata> {
    return new HttpResponse({
      status: 200,
      body: {
        actionable_items_count: {
          digital: Math.floor(Math.random() * 10),
          original: Math.floor(Math.random() * 10),
          upload: Math.floor(Math.random() * 10),
        },
      },
    });
  }

  public getDocumentTypeClients(request: HttpRequest<any>, id: string): HttpResponse<DocumentTypeClientBatch> {
    const limit = Number(request.params.get('limit'));
    const offset = Number(request.params.get('offset'));

    const clients = this.nonDiscretionClientsMap.get(id) || this.discretionClientsMap.get(id);

    const clientBatch: DocumentTypeClientBatch = {
      items: paginate(clients, limit, offset),
      total_count: clients.length,
    };

    return new HttpResponse({
      status: 200,
      body: clientBatch,
    });
  }

  public getDocumentTypeClientsMetadata(_: HttpRequest<any>, id: string): HttpResponse<DocumentTypeClientsMetadata> {
    const clients = this.discretionClientsMap.get(id);
    const clientsMetadata: DocumentTypeClientsMetadata = {
      total_count: clients.length,
      status_counts: clients.reduce<DocumentTypeClientStatusCount[]>(
        (statusCounts, client) => {
          const statusCount = statusCounts.find((count) => count.status === client.status);
          if (!statusCount) {
            const newStatusCount = { status: client.status, count: 1 };
            return statusCounts.concat(newStatusCount);
          }
          const updatedStatusCount = { status: client.status, count: statusCount.count + 1 };
          return statusCounts.filter((count) => count !== statusCount).concat(updatedStatusCount);
        },
        DISCRETION_DIGITAL_DOCUMENT_TYPE_STATUSES.map((status) => ({ status, count: 0 }))
      ),
      processing_count: Math.round(Math.random() * 10),
    };

    return new HttpResponse({
      status: 200,
      body: clientsMetadata,
    });
  }

  public signDocumentType(_: HttpRequest<any>, __: string): HttpResponse<void> {
    return new HttpResponse({ status: 200 });
  }

  public markDocumentTypeAsPrinted(_: HttpRequest<any>, __: string): HttpResponse<void> {
    return new HttpResponse({ status: 200 });
  }

  public uploadDocumentType(_: HttpRequest<any>, __: string): HttpResponse<void> {
    return new HttpResponse({ status: 200 });
  }
}
