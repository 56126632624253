import { HttpRequest, HttpResponse } from '@angular/common/http';
import { BankingDetails, Payment, PaymentBatch, PaymentConfirmation, PaymentConfirmationStatus, PendingPayment } from '@wtax/data-angular';
import { paginate, parseToken, randomBool, randomDate, randomElement, randomId } from '../functions';
import { ClientManagementMockService } from './client-management-mock.service';

const PAYMENT_COUNT = 100;
const PAYMENT_CONFIRMATION_COUNT = 15;

const clientNames = [
  'Liam Smith',
  'Noah Johnson',
  'Oliver Williams',
  'William Brown',
  'Elijah Jones',
  'James Garcia',
  'Benjamin Miller',
  'Lucas Davis',
  'Mason Rodriguez',
  'Ethan Martinez',
  'Alexander Hernandez',
  'Henry Lopez',
  'Jacob Gonzalez',
  'Michael Wilson',
  'Daniel Anderson',
  'Logan Thomas',
  'Jackson Taylor',
  'Sebastian Moore',
  'Jack Jackson',
  'Aiden Martin',
  'Olivia Smith',
  'Emma Johnson',
  'Ava Williams',
  'Sophia Brown',
  'Isabella Jones',
  'Charlotte Garcia',
  'Amelia Miller',
  'Mia Davis',
  'Harper Rodriguez',
  'Evelyn Martinez',
  'Abigail Hernandez',
  'Emily Lopez',
  'Ella Gonzalez',
  'Elizabeth Wilson',
  'Camila Anderson',
  'Luna Thomas',
  'Sofia Taylor',
  'Avery Moore',
  'Mila Jackson',
  'Aria Martin',
];

export class PaymentsMockService {
  private wmPayments: Payment[] = [];
  private fmPayments: Payment[] = [];
  private boPayments: Payment[] = [];
  private paymentConfirmations: PaymentConfirmation[] = [];
  private pendingPayments: PendingPayment[] = [];

  constructor(private readonly clientManagementMockService: ClientManagementMockService) {
    // WM payments
    for (let i = 0; i < PAYMENT_COUNT; i++) {
      this.wmPayments.push({
        payment_date: randomDate('2019-01-01'),
        report_url: `https://downloadreport.com/${randomId()}.pdf`,
      });
    }
    this.wmPayments = this.wmPayments.sort((a, b) => new Date(b.payment_date).getTime() - new Date(a.payment_date).getTime());

    // FM payments
    for (let i = 0; i < PAYMENT_COUNT; i++) {
      this.fmPayments.push({
        payment_date: randomDate('2019-01-01'),
        report_url: `https://downloadreport.com/${randomId()}.pdf`,
      });
    }
    this.fmPayments = this.fmPayments.sort((a, b) => new Date(b.payment_date).getTime() - new Date(a.payment_date).getTime());

    // BO payments
    // FM payments
    for (let i = 0; i < PAYMENT_COUNT; i++) {
      this.boPayments.push({
        payment_date: randomDate('2019-01-01'),
        report_url: `https://downloadreport.com/${randomId()}.pdf`,
      });
    }
    this.boPayments = this.boPayments.sort((a, b) => new Date(b.payment_date).getTime() - new Date(a.payment_date).getTime());

    // payment confirmations
    for (let i = 0; i < PAYMENT_CONFIRMATION_COUNT; i++) {
      const status: PaymentConfirmationStatus = randomBool()
        ? PaymentConfirmationStatus.MISSINGBANKINGDETAILS
        : PaymentConfirmationStatus.COMPLETED;
      const completed = status === PaymentConfirmationStatus.COMPLETED;

      const clientName: any = randomElement(clientNames);

      this.paymentConfirmations.push({
        client: {
          id: randomId(),
          name: clientName,
          client_code: randomId(),
        },
        currency_code: randomElement(['USD', 'EUR', 'UAH']),
        account_number: completed ? '12345678-12345678' : null,
        swift_code: completed ? '024681012' : null,
        status,
      });
    }
    this.paymentConfirmations = this.paymentConfirmations.sort((a, _) => (a.status === PaymentConfirmationStatus.COMPLETED ? 1 : -1));

    // create mock banking details for
    this.paymentConfirmations.forEach((pc) => {
      this.clientManagementMockService.setBankingDetails(pc.client.id, pc.currency_code, {
        account: {
          bank_name: 'HSBC',
          account_holder_name: pc.client.name,
          account_number: pc.account_number,
          swift_code: pc.swift_code,
          currency_code: pc.currency_code,
        },
        needs_more_details: randomBool(),
        beneficiary: null,
        intermediary: null,
      });
    });

    const paymentConfirmationsToConfirm = this.paymentConfirmations.splice(0, 2);

    this.pendingPayments = this.convertPaymentConfirmationsToPendingPayments(paymentConfirmationsToConfirm);
  }

  private convertPaymentConfirmationsToPendingPayments(paymentConfirmations: PaymentConfirmation[]): PendingPayment[] {
    return paymentConfirmations.map((confirmation) => ({
      client: {
        client_code: confirmation.client?.client_code,
        name: confirmation.client?.name,
      },
      currency: confirmation.currency_code,
      account_number: confirmation.account_number,
      swift_code: confirmation.swift_code,
    }));
  }

  public getPayments(request: HttpRequest<any>): HttpResponse<PaymentBatch> {
    const token = parseToken(request);
    const limit = Number(request.params.get('limit'));
    const offset = Number(request.params.get('offset'));

    let list: Payment[];

    if (token.startsWith('WM')) {
      list = this.wmPayments;
    } else if (token.startsWith('FM')) {
      list = this.fmPayments;
    } else if (token.startsWith('BO')) {
      list = this.boPayments;
    }

    const paymentBatch: PaymentBatch = {
      items: paginate(list, limit, offset),
      total_count: list.length,
    };

    return new HttpResponse({ status: 200, body: paymentBatch });
  }

  public getPaymentConfirmations(request: HttpRequest<any>): HttpResponse<PaymentConfirmation[]> {
    const token = parseToken(request);

    if (token === 'BO') {
      this.paymentConfirmations = this.paymentConfirmations.slice(0, 1);
      this.pendingPayments = [];
      return new HttpResponse({ status: 200, body: randomBool() ? this.paymentConfirmations : [] });
    }

    if (token === 'FM') {
      this.paymentConfirmations = this.paymentConfirmations.map((c) => {
        // no name for funds
        c.client.name = null;
        return c;
      });
    }

    if (token === 'WM:payment-confirmations-completed') {
      this.paymentConfirmations = this.paymentConfirmations.filter((c) => c.status === PaymentConfirmationStatus.COMPLETED);
    }

    return new HttpResponse({ status: 200, body: this.paymentConfirmations });
  }

  public submitPaymentConfirmations(_: HttpRequest<any>): HttpResponse<any> {
    this.pendingPayments = this.pendingPayments.concat(this.convertPaymentConfirmationsToPendingPayments(this.paymentConfirmations));

    this.paymentConfirmations = [];

    return new HttpResponse({ status: 200 });
  }

  public getPendingPayments(_: HttpRequest<any>): HttpResponse<PendingPayment[]> {
    return new HttpResponse({ status: 200, body: this.pendingPayments });
  }

  public markDetailsDone(clientId: string, request: HttpRequest<BankingDetails>): void {
    const confirmation = this.paymentConfirmations.find((pc) => pc.client.id === clientId);

    if (!confirmation) {
      return;
    }

    confirmation.account_number = request.body.account.account_number;
    confirmation.swift_code = request.body.account.swift_code;
    confirmation.status = PaymentConfirmationStatus.COMPLETED;
  }
}
